import React, {useState} from 'react';
import {Value} from 'sass';
import {ContentItem, MenuItem, ContentWrapper, ContentImageWrapper, About, Greeting} from './elements/sections';
import TextTransition, { presets } from 'react-text-transition';

export class App extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            theme: 'dark--theme',
            year: this.getCurrentYear(),
            menu: 'greetings',
            my_name: 'ma-he-sh',
        }
    }

    getCurrentYear = () => {
        const curr_date = new Date();
        return curr_date.getFullYear();
    }

    showContent = (menu_item:any) => {
        this.setState({menu: menu_item});
    }

    onMouseOver = () => {
        this.setState({my_name: 'Mahesh Ranaweera'});
    }

    onMouseLeave = () => {
        this.setState({my_name: 'ma-he-sh'});
    }

    render() {
        let content: any;
        switch (this.state.menu) {
            case 'greetings':
                content = <Greeting/>
                break;
            case 'projects':
                //content = this.props.data_source.hasOwnProperty('projects') && this.props.data_source.projects.length > 0 ? this.props.data_source.projects : [];
                break;
            case 'research':
                //content = this.props.data_source.hasOwnProperty('research') && this.props.data_source.research.length > 0 ? this.props.data_source.research : [];
                break;
            case 'publication':
                var items = this.props.data_source.hasOwnProperty('publication') && this.props.data_source.publication.length > 0 ? this.props.data_source.publication : [];
                content = <ContentWrapper content={items}/>
                break;
            case 'photography':
                var items = this.props.data_source.hasOwnProperty('photography') && this.props.data_source.photography.length > 0 ? this.props.data_source.photography : [];
                content = <ContentImageWrapper content={items} />
                break;
            case 'about':
            default:
                var items = this.props.data_source.hasOwnProperty('about') && this.props.data_source.about.length > 0 ? this.props.data_source.about : [];
                content = <div>
                    {/*<About/>*/}
                    <Greeting/>
                    <ContentWrapper content={items} />
                </div>
                break;
        }

        return <>
            <div className='app--wrapper'>
                <div className='app--header-wrapper'>
                    <div className='app--header-content'>
                        <div className='app--site-name' onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}><TextTransition inline={true} springConfig={presets.wobbly}>{this.state.my_name}</TextTransition></div>
                    </div>
                </div>
                <div className='app--content-wrapper'>
                    <div className='app--content-content'>
                        <div className='app--primary-menu'>
                            <div className='app--menu-items'>
                                {/*<MenuItem name='projects' data-menu='Projects' onClick={(e:any) => this.showContent('projects')}/>*/}
                                {/*<MenuItem name='research' data-menu='Research' onClick={(e:any) => this.showContent('research')}/>*/}
                                <MenuItem name='publication' data-menu='Publications' onClick={(e:any) => this.showContent('publication')}/>
                                {/*<MenuItem name='photography' data-menu='Photography' onClick={(e:any) => this.showContent('photography')}/>*/}
                                <MenuItem name='about' data-menu='About' onClick={(e:any) => this.showContent('about')}/>
                            </div>
                        </div>
                        <div className='app--primary-content'>
                            <div className='app--content-items'>{content} </div>
                        </div>
                    </div>
                </div>
                <div className='app--footer-wrapper'>
                    <div className='app--footer-content'>
                        <div className='app--copyright'>ma-he-sh {this.state.year}</div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default App;
