import React, {useState, useEffect} from 'react';
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown';
import {GitHub} from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextTransition, { presets } from 'react-text-transition';

export function MenuItem(props:any) {
	return <div className='app--menu-item' {...props}>{props.name}</div>
}

export function ContentItem(props:any) {
	const [content, setContent] = useState('')
	const show_border_el = (props.show_border) ? 'app--content-item no--border' : 'app--content-item';
	const file_name = props.file;

	import(`../data/markdown/${file_name}`).then((res) => {
		fetch(res.default)
			.then(res => res.text())
			.then(res => setContent(res))
			.catch(err => console.log(err));
	}).catch(err => console.log(err));

	return <div className={show_border_el}>
		{props.show_counter ?
			<div className="content--count">{props.index}</div>
			: <></>
		}
		{props.header_title && props.header_title.length > 0 ?
			<div className='content--header'>{props.header_title}</div>
			: <></>
		}
		{ props.image && props.image.length > 0 ?
			<div className='content--image'>
				<img src={props.image}/>
			</div>
			: <></>
		}
		{ content && content.length > 0 ?
			<div className='content-content markdown-body'><ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown></div>
			: <></>
		}
	</div>
}

export function ContentWrapper(props:any) {
	const count=(props.content.length);

	return <div>
		{props.content.length > 0 ?
			<>
				{props.content.map((item:any, index: number) => (
					<ContentItem key={index} index={index + 1} header_title={item.header_title} show_border={item.border}
								 image={item.image} file={item.markdown} show_counter={item.show_counter}/>
				))}
			</>
			: <></>
		}
	</div>
}

export function ContentImageWrapper(props:any) {
	return <div>
		{props.content.length > 0 ?
			<>
				{props.content.map((image:any, index:number) => (
					<div key={index} className='app--content-item'>
						<div className="content--image">
							<a href={image.url}>
								<LazyLoadImage src={image.image} width={"100%"}/>
							</a>
							<div className="image--description">{image.description}</div>
						</div>
					</div>
				))}
			</>
			: <></>
		}
	</div>
}

const texts:Array<string> = ['PhD Candidate',  'AI Researcher', 'Full-stack Developer', 'Lifelong Learner', 'Designer'];
const descriptions:Array<string> = [
	'PhD Candidate working on sim2real applications, bridging the gap between simulated environments and real-world implementation',
	'AI Researcher in computer vision, robotics, and autonomous applications',
	'Full-stack Developer working on interesting personal and collaborative projects in media streaming and building learning application',
	'Lifelong Learner in every aspect',
	'3D Printing, Robotics, Photography, Electronics and Graphic Designing'
];

export function Greeting(props:any) {
	const [index, setString] = useState(0);
	const [index_2, setDescription] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			// @ts-ignore
			if( index === texts.length - 1) {
				// @ts-ignore
				setString(0);
				setDescription(0);
			} else {
				setString(index + 1);
				setDescription(index_2 + 1)
			}
		}, 5000);
		return () => clearInterval(timer);
	}, [index, index_2]);

	// @ts-ignore
	var text = texts[index];
	var descript = descriptions[index_2];

	return <div className="content--about">
		<>Hello World 🌎, I am Mahesh Ranaweera a <span className="animated--transition"><TextTransition inline={true} springConfig={presets.wobbly}>{text}</TextTransition></span>.</>
		<div className="content--more">&#187;<span className="animated--transition"><TextTransition inline={false} springConfig={presets.wobbly}>{descript}</TextTransition></span></div>
	</div>
}

export function About(props:any) {
	const [index, setString] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			// @ts-ignore
			if( index === texts.length - 1) {
				// @ts-ignore
				setString(0);
			} else {
				setString(index + 1);
			}
		}, 5000);
		return () => clearInterval(timer);
	}, [index]);

	// @ts-ignore
	var text = texts[index];

	return <div className="content--about">
		Hello World 🌎, I am Mahesh Ranaweera a <span className="animated--transition"><TextTransition inline={true} springConfig={presets.wobbly}>{text}</TextTransition></span>.
	</div>
}
